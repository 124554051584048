<template>
    <div
        ref="wrapper"
        :class="{
            input: true,
            [`input_${size}`]: true,
            input_outlined: outlined,
            'input_with-hint': hint,
        }"
    >
        <template v-if="hint">
            <info-icon class="input__hint-icon" />
            <p class="input__hint" v-html="hint" />
        </template>

        <div class="input__input-wrapper">
            <span v-if="hasPrefix" ref="prefix" class="input__prefix">
                <slot name="prefix" />
            </span>

            <input ref="input" v-model="text" v-bind="$attrs" v-on="listeners" :class="{ input__input: true, 'input__input_with-icon': hasSuffix }" />

            <div v-if="hasSuffix" class="input__suffix">
                <slot name="suffix" />
            </div>
        </div>
    </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
    import InfoIcon from '@/components/icons/InfoIcon';

    export default {
        name: 'InputComponent',
        inheritAttrs: false,
        components: {
            InfoIcon,
        },
        props: {
            hint: String,
            value: [String, Number],
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            outlined: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            hasSuffix() {
                return !!this.$slots.suffix;
            },
            hasPrefix() {
                return !!this.$slots.prefix;
            },
            listeners() {
                delete this.$listeners['input'];
                return this.$listeners;
            },
            text: {
                get() {
                    return this.value;
                },
                set(text) {
                    this.$emit('input', text);
                },
            },
        },
        methods: {
            adjustPrefixSlot() {
                if (this.hasPrefix && 'svg' === this.$refs.prefix.children[0].tagName) {
                    this.$refs.prefix.classList.add('input__prefix_squared');
                }
            },
        },
        mounted() {
            this.adjustPrefixSlot();
        },
    };
</script>

<style lang="scss" scoped>
    .input {
        position: relative;
        display: flex;
        align-items: center;

        &_outlined &__input {
            background-color: transparent;
            border: 1px solid #1a2030;
        }

        &_medium &__input,
        &_medium &__prefix {
            height: 4.6rem;
            padding: 0 1.5rem;
            font-size: 1.2rem;
        }

        &_medium &__prefix_squared {
            width: 4.6rem;
        }

        &_medium &__input_with-icon {
            padding-right: 5.8rem;
        }

        &_medium &__suffix {
            width: 5.8rem;

            svg {
                width: 1.8rem;
            }
        }

        &_small &__input,
        &_small &__prefix {
            height: 3.6rem;
            padding: 0 1rem;
            font-size: 1.2rem;
        }

        &_small &__prefix_squared {
            width: 3.6rem;
        }

        &_small &__input_with-icon {
            padding-right: 3.6rem;
        }

        &_small &__suffix {
            width: 3.6rem;

            svg {
                width: 1.6rem;
            }
        }

        &_with-hint {
            &.input_medium .input__input {
                padding-right: 5.2rem;
            }
        }
    }

    .input__input-wrapper {
        display: flex;
        width: 100%;
    }

    .input__prefix {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-right: 0.1rem;
        border-radius: 0.8rem 0 0 0.8rem;
        background-color: #171f33;
        color: #808ba7;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 325;

        svg {
            width: 1.8rem;
            height: 1.8rem;
            fill: #616e8e;
        }

        + input {
            border-radius: 0 0.8rem 0.8rem 0;
        }

        &_squared {
            justify-content: center;
            padding: 0 !important;
        }
    }

    .input__input {
        width: 100%;
        border-radius: 0.8rem;
        background-color: #1b2232;
        color: #ccd1de;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 400;
        text-overflow: ellipsis;
        transition: background-color 0.3s;

        &:disabled,
        &:focus {
            background-color: #1b2131;
        }

        &::placeholder {
            color: #585f75;
            text-overflow: ellipsis;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            appearance: textfield;
            -moz-appearance: textfield;
        }
    }

    .input__suffix {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        justify-content: center;
        height: 100%;
        transform: translateY(-50%);

        button {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
        }

        svg {
            fill: #353e53;
            pointer-events: none;
        }
    }

    .input__hint {
        display: none;
        position: absolute;
        top: -2rem;
        right: 2.5rem;
        width: max-content;
        padding: 1rem 2.2rem;
        border-radius: 0.5rem;
        background-color: rgba(104, 118, 151, 0.6);
        backdrop-filter: blur(3px);
        color: #ccd1de;
        font-family: 'Gotham Medium', sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        transform: translate(50%, -50%);

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -0.5rem;
            border: 0.5rem solid;
            border-color: rgba(104, 118, 151, 0.6) transparent transparent transparent;
        }
    }

    .input__hint-icon {
        position: absolute;
        top: 50%;
        right: 1.8rem;
        width: 1.6rem;
        height: 1.6rem;
        transform: translate(0, -50%);
        cursor: pointer;
        fill: #3f465a;
        transition: fill 0.3s;

        &:hover {
            fill: #4b5975;

            & + .input__hint {
                display: block;
            }
        }
    }

    @media screen and (min-width: 22.5em) {
        .input {
            &_medium &__input,
            &_medium &__prefix {
                height: 4.8rem;
                font-size: 1.3rem;
            }

            &_medium &__prefix_squared {
                width: 4.8rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .input {
            &_medium &__input,
            &_medium &__prefix {
                height: 5.2rem;
                padding: 0 2rem;
                font-size: 1.5rem;
            }

            &_medium &__prefix_squared {
                width: 5.2rem;
            }

            &_medium &__input_with-icon {
                padding-right: 6.2rem;
            }

            &_medium &__suffix {
                width: 6.2rem;

                svg {
                    width: 2.2rem;
                }
            }

            &_small &__input,
            &_small &__prefix {
                height: 4.4rem;
                padding: 0 1.5rem;
                font-size: 1.3rem;
            }

            &_small &__prefix_squared {
                width: 4.4rem;
            }

            &_small &__input_with-icon {
                padding-right: 5rem;
            }

            &_small &__suffix {
                width: 5rem;

                svg {
                    width: 2rem;
                }
            }
        }

        .input__prefix {
            svg {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }
</style>
