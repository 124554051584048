<template>
    <auth-layout>
        <auth-form back-route="auth-new-password" :action="action" :callback="submit" theme="success">
            <template #header>
                <h1>{{ $t('auth_new_password_setup.title') }}</h1>
                <p>{{ $t('auth_new_password_setup.subtitle') }}</p>
            </template>

            <validation-provider name="password" rules="required|min:8" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_new_password_setup.password.label')" :errors="errors">
                    <password-input
                        v-model="password"
                        :placeholder="$t('auth_new_password_setup.password.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <validation-provider name="password_confirmation" rules="required|confirmed:password" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_new_password_setup.password_confirmation.label')" :errors="errors">
                    <password-input
                        v-model="passwordConfirmation"
                        :placeholder="$t('auth_new_password_setup.password_confirmation.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <template #button>
                {{ $t('auth_new_password_setup.submit_button') }}
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import InputGroup from '@/components/inputs/InputGroup';
    import PasswordInput from '@/components/inputs/PasswordInput';
    import Toast from '@/components/Toast';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { resetPassword } from '@/services/skinsPaymentApi';

    export default {
        name: 'NewPasswordSetup',
        components: {
            ValidationProvider,
            AuthForm,
            InputGroup,
            PasswordInput,
            AuthLayout,
        },
        computed: {
            action() {
                return 'user-cabinet-api/auth/password-reset';
            },
        },
        methods: {
            submit() {
                const email = sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');
                const code = sessionStorage.getItem('AUTH_NEW_PASSWORD_CODE');

                return resetPassword(email, code, this.password, this.passwordConfirmation).then(() => {
                    sessionStorage.removeItem('AUTH_NEW_PASSWORD_EMAIL');
                    sessionStorage.removeItem('AUTH_NEW_PASSWORD_CODE');
                    sessionStorage.removeItem('AUTH_NEW_PASSWORD_TIMER_END');

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_new_password_setup.toasts.submit_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-login' });
                });
            },
        },
        data() {
            return {
                password: '',
                passwordConfirmation: '',
            };
        },
        beforeRouteEnter(to, from, next) {
            const email = sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');
            const code = sessionStorage.getItem('AUTH_NEW_PASSWORD_CODE');

            if (email === null || code === null) {
                next({ name: 'auth-new-password' });
            } else {
                next();
            }
        },
    };
</script>
