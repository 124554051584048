<template>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.0616 14.6513L4.93505 4.52505L4.93288 4.52258C4.87806 4.4682 4.81306 4.42515 4.7416 4.39589C4.67014 4.36664 4.59361 4.35174 4.51639 4.35206C4.43918 4.35237 4.36278 4.36789 4.29156 4.39774C4.22034 4.42758 4.1557 4.47115 4.10132 4.52598C4.04694 4.5808 4.00389 4.6458 3.97464 4.71726C3.94538 4.78872 3.93048 4.86525 3.9308 4.94246C3.93143 5.09841 3.994 5.24772 4.10472 5.35754L4.99133 6.24415C3.91611 6.98054 2.99062 7.91454 2.26409 8.99644C2.14545 9.17161 2.08203 9.37832 2.08203 9.58988C2.08203 9.80145 2.14545 10.0082 2.26409 10.1833C4.11864 12.9282 7.05864 14.5363 9.99864 14.5162C10.951 14.5196 11.8969 14.3611 12.7961 14.0474L14.2313 15.4826C14.3419 15.5896 14.49 15.6489 14.6439 15.6479C14.7978 15.6468 14.9451 15.5855 15.0542 15.477C15.1633 15.3685 15.2255 15.2215 15.2274 15.0676C15.2293 14.9137 15.1708 14.7653 15.0644 14.6541L15.0616 14.6513ZM9.99864 12.9201C9.44162 12.9199 8.89354 12.7801 8.40448 12.5135C7.91542 12.2469 7.50098 11.862 7.19903 11.3939C6.89708 10.9258 6.71725 10.3895 6.67598 9.83406C6.63471 9.27857 6.73331 8.7216 6.96277 8.21405L7.88896 9.14024C7.81436 9.49179 7.82877 9.8564 7.9309 10.201C8.03303 10.5455 8.21962 10.8591 8.47374 11.1132C8.72786 11.3673 9.04145 11.5539 9.38601 11.6561C9.73057 11.7582 10.0952 11.7726 10.4467 11.698L11.3729 12.6242C10.9412 12.8199 10.4726 12.9209 9.99864 12.9201Z"
        />

        <path
            d="M17.7351 8.99504C15.8806 6.24863 12.9406 4.64055 10.0006 4.66065C9.04825 4.6572 8.10228 4.81573 7.20312 5.12946L8.62566 6.552C9.24294 6.27265 9.93069 6.18806 10.5973 6.3095C11.2638 6.43095 11.8776 6.75266 12.3567 7.23175C12.8358 7.71085 13.1575 8.32459 13.2789 8.99116C13.4004 9.65773 13.3158 10.3455 13.0364 10.9628L15.0076 12.9339C16.083 12.1976 17.0086 11.2636 17.7351 10.1816C17.8537 10.0065 17.9171 9.79983 17.9171 9.58833C17.9171 9.37682 17.8537 9.17018 17.7351 8.99504Z"
        />

        <path
            d="M9.99888 7.43164C9.84828 7.43166 9.6981 7.44741 9.55078 7.47865L12.1086 10.0361C12.1752 9.72189 12.1709 9.39673 12.0959 9.0844C12.0208 8.77207 11.8769 8.48045 11.6747 8.23084C11.4725 7.98123 11.2172 7.77994 10.9272 7.64167C10.6373 7.5034 10.3201 7.43164 9.99888 7.43164Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'EyeCrossIcon',
    };
</script>
