<template>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 0.5C3.8675 0.5 0.5 3.8675 0.5 8C0.5 12.1325 3.8675 15.5 8 15.5C12.1325 15.5 15.5 12.1325 15.5 8C15.5 3.8675 12.1325 0.5 8 0.5ZM8.75 11C8.75 11.4125 8.4125 11.75 8 11.75C7.5875 11.75 7.25 11.4125 7.25 11V8C7.25 7.5875 7.5875 7.25 8 7.25C8.4125 7.25 8.75 7.5875 8.75 8V11ZM8 5.75C7.5875 5.75 7.25 5.4125 7.25 5C7.25 4.5875 7.5875 4.25 8 4.25C8.4125 4.25 8.75 4.5875 8.75 5C8.75 5.4125 8.4125 5.75 8 5.75Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'InfoIcon',
    };
</script>
