<template>
    <input-component ref="input" autocomplete="current-password" :type="type" v-bind="{ ...$attrs, value }" v-on="$listeners">
        <template #suffix>
            <button type="button" @click="show = !show">
                <component :is="iconComponent" />
            </button>
        </template>
    </input-component>
</template>

<script>
    import EyeIcon from '@/components/icons/EyeIcon';
    import EyeCrossIcon from '@/components/icons/EyeCrossIcon';
    import InputComponent from '@/components/inputs/InputComponent';

    export default {
        name: 'PasswordInput',
        components: {
            EyeIcon,
            EyeCrossIcon,
            InputComponent,
        },
        props: {
            value: [Number, String],
        },
        computed: {
            iconComponent() {
                return this.show ? 'eye-icon' : 'eye-cross-icon';
            },
            type() {
                return this.show ? 'text' : 'password';
            },
        },
        data() {
            return {
                show: false,
            };
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .input__input {
            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }
        }

        .input__suffix svg {
            fill: #52596f !important;
        }
    }
</style>
