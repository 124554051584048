<template>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0001 12.4391C11.3463 12.4391 12.4376 11.3478 12.4376 10.0015C12.4376 8.6553 11.3463 7.56396 10.0001 7.56396C8.65384 7.56396 7.5625 8.6553 7.5625 10.0015C7.5625 11.3478 8.65384 12.4391 10.0001 12.4391Z"
        />

        <path
            d="M17.6725 9.32625C15.7988 7.06206 12.9625 4.88257 9.99872 4.88257C7.03433 4.88257 4.1974 7.06358 2.32495 9.32625C2.00106 9.71748 2.00106 10.2854 2.32495 10.6767C2.79571 11.2455 3.78262 12.3437 5.10073 13.3031C8.42036 15.7198 11.5698 15.7251 14.8967 13.3031C16.2148 12.3437 17.2017 11.2455 17.6725 10.6767C17.9954 10.2862 17.9971 9.71876 17.6725 9.32625ZM9.99872 6.58887C11.8805 6.58887 13.4113 8.11966 13.4113 10.0015C13.4113 11.8833 11.8805 13.4141 9.99872 13.4141C8.11692 13.4141 6.58612 11.8833 6.58612 10.0015C6.58612 8.11966 8.11692 6.58887 9.99872 6.58887Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'EyeIcon',
    };
</script>
